<template>
  <!-- <v-card class="mb-4" @click="_selectQuestion"> -->
  <v-card class="mb-4">
    <v-container style="margin-bottom: 10px">
      <v-row>
        <!-- <p>{{question.id}}</p> -->
        <span class="numberOfquestion">{{ qindex+1 }}</span>
        <v-spacer />
        <v-col
          class="xs"
          sm="2"
          md="2"
        >
          <v-text-field
            v-model="question.score"
            :rules="rules"
            counter
            maxlength="3"
            label="Оноо"
            hint="Оноо оруулаx"
            @keydown.enter="_saveScore"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="xs"
          sm="11"
          md="6"
        >
          <v-text-field
            v-model="question.name"
            :rules="rules"
            counter
            maxlength="25"
            hint="This field uses maxlength attribute"
            label="Асуултын нэр"
            @keydown.enter="_saveName"
          />
        </v-col>

        <v-col
          sm="1"
          md="1"
          align-self="center"
          class="pa-0 xs"
        >
          <v-btn
            icon
            @click="_addImageToQuestion"
          >
            <v-icon>mdi-image-outline</v-icon>
          </v-btn>
        </v-col>

        <v-col
          class="flex-grow-1"
          md="5"
        >
          <v-select
            v-model="questionType"
            :items="questionTypesList"
            item-text="name"
            item-value="value"
            filled
            label="Асуултын төрөл"
            dense
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="5">
          <v-text-field
            v-model="question.description"
            label="Тайлбар"
            placeholder="Тайлбар..."
            @keydown.enter="_saveDescription"
          />
        </v-col>
      </v-row>

      <v-row v-if="question.images!=null && question.images.length>0">
        <div
          v-for="(file, index) in question.images"
          :key="index"
          class="col-md-12"
        >
          <a
            href="#"
            class="mb-4 h-100"
          >
            <v-img :src="file.fileUrl" />
          </a>
        </div>
      </v-row>
      <v-row
        v-for="(answer, aindex) in question.answers"
        :key="aindex"
      >
        <v-col
          cols="11"
          sm="11"
        >
          <v-text-field
            v-model="answer.name"
            prepend-icon="mdi-checkbox-blank-circle-outline"
            append-icon="mdi-window-close"
            @click:append="_deleteAnswer(answer)"
            @keydown.enter="_editAnswer(answer)"
          />
        </v-col>
        <v-checkbox
          v-model="answer.correct"
          hide-details
          class="shrink mr-2 mt-0"
          @click="_chooseCorrectAnswer(question, answer)"
        />
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="11"
        >
          <v-text-field
            v-model="lastQ.name"
            label="Шинэ xариулт оруулаx..."
            prepend-icon="mdi-checkbox-blank-circle-outline"
            @keydown.enter="_addAnswer(lastQ)"
          />
        </v-col>
      </v-row>
      <!-- <hr/> -->
      <v-toolbar flat>
        <v-spacer />
        <v-btn
          icon
          @click="_deleteQuestion"
        >
          <v-icon>mdi-trash-can-outline</v-icon>
        </v-btn>

        <v-btn
          icon
          @click="_copyQuestion(question)"
        >
          <v-icon>mdi-content-copy</v-icon>
        </v-btn>

        <v-btn
          icon
          @click="_addQuestion"
        >
          <v-icon>mdi-plus-circle-outline</v-icon>
        </v-btn>

        <v-btn icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </v-toolbar>
    </v-container>
  </v-card>
</template>
<style>
.v-ripple__container {
    display:none !important;
}
</style>
<script>
  const fb = require('../../firebaseConfig.js')
  export default {
    filters: {
      capitalize: function (value) {
        if (!value) return ''
        value = value.toString()
        // return value.charAt(0).toUpperCase() + value.slice(1)
        return value.toUpperCase()
      },
    },
    props: {
      asuulgaId: {
        type: String,
        required: false,
      },
      question: {
        type: Object,
        required: false,
      },
      qindex: {
        type: Number,
        required: false,
      },
      category: {
        type: Object,
        required: false,
      },

    },
    data: () => ({
      lastQ: { name: '', status: false },
      color: '#ccc',
      description: null,
      value: null,
      rules: [],
      questionType: null,
      questionTypesList: [{ name: 'Нэг сонголт', value: 0 }, { name: 'Олон сонголт', value: 1 }, { name: 'Нээлттэй xариулт', value: 2 }],
    }),
    watch: {
      questionType: function(val) {
        console.log(val)
        if (val === 0) { this.question.multichoice = false } else { this.question.multichoice = true }

        this._updateQuestion()
      },
    },
    created() {
      if (this.question.multichoice) { this.questionType = { name: this.questionTypesList[1], value: 1 } } else { this.questionType = { name: this.questionTypesList[0], value: 0 } }
    },
    methods: {
      _chooseCorrectAnswer(question, answer) {
        console.log(question, answer.correct)

        var path = 'asuulga/' + this.asuulgaId + '/categories/' + this.category.id + '/questions/' + this.question.id + '/answers'
        fb.db.collection(path).doc(answer.id).set(answer)
      },
      _addImageToQuestion() {
        this.$router.push({ name: 'ImageAdd', params: { objectPath: 'asuulga/' + this.asuulgaId + '/categories/' + this.category.id + '/questions/' + this.question.id } })
      },
      _updateQuestion() {
        var docRef
        if (this.category != null) {
          docRef = fb.db.collection('asuulga/' + this.asuulgaId + '/categories/' + this.category.id + '/questions/').doc(this.question.id)
        } else {
          docRef = fb.db.collection('asuulga/' + this.asuulgaId + '/questions/').doc(this.question.id)
        }
        docRef.set(this.question)
      },
      _saveScore() {
        var docRef
        if (this.category != null) {
          docRef = fb.db.collection('asuulga/' + this.asuulgaId + '/categories/' + this.category.id + '/questions/').doc(this.question.id)
        } else {
          docRef = fb.db.collection('asuulga/' + this.asuulgaId + '/questions/').doc(this.question.id)
        }
        docRef.update({ score: parseInt(this.question.score) })
      },
      _saveName() {
        this._updateQuestion()
      },
      _saveDescription() {
        this._updateQuestion()
      },
      async _copyQuestion(question) {
        // TODO
        console.log(question)
        var docRef
        if (this.category != null) {
          docRef = fb.db.collection('asuulga/' + this.asuulgaId + '/categories/' + this.category.id + '/questions/')
        } else {
          docRef = fb.db.collection('asuulga/' + this.asuulgaId + '/questions/')
        }
        var newQuestionId = await docRef.add({ name: this.question.name, description: this.question.description, multichoice: this.question.multichoice, createdAt: new Date(), index: 0 })
        console.log(newQuestionId, this.question)
        // var pathAnswers='asuulga/'+this.asuulgaId+'/categories/'+this.category.id+ '/questions/' + newQuestionId.id+'/answers';
        for (var answer of this.question.answers) {
          console.log(answer)
        // var newAnswer = {name: answer.name, createdAt: new Date(), index:0 , correct:null};
        // fb.db.collection(pathAnswers).doc().set(newAnswer);
        }
      },
      _deleteAnswer(answer) {
        console.log(answer.id)
        var path = 'asuulga/' + this.asuulgaId + '/categories/' + this.category.id + '/questions/' + this.question.id + '/answers/' + answer.id
        fb.db.doc(path).delete()
      },
      _editAnswer(answer) {
        console.log(answer)
        // var newAnswer = {name: answer.name, createdAt: new Date(), index:0 , correct:null};
        var path = 'asuulga/' + this.asuulgaId + '/categories/' + this.category.id + '/questions/' + this.question.id + '/answers'
        fb.db.collection(path).doc(answer.id).set(answer)

        // // var object= Object.assign({}, this.lastQ);
        // // this.question.answers.push(object);
        // this.lastQ={name:'', status:false};
      },
      _addAnswer(answer) {
        console.log(answer)
        var newAnswer = { name: answer.name, createdAt: new Date(), index: 0, correct: null }
        var path = 'asuulga/' + this.asuulgaId + '/categories/' + this.category.id + '/questions/' + this.question.id + '/answers'
        fb.db.collection(path).doc().set(newAnswer)

        // var object= Object.assign({}, this.lastQ);
        // this.question.answers.push(object);
        this.lastQ = { name: '', status: false }
      },
      _addQuestion() {
        console.log('dddd')
        var docRef
        var newQuestion = { name: '', createdAt: new Date(), index: 0 }
        var path = 'asuulga/' + this.asuulgaId + '/categories/' + this.category.id + '/questions'
        if (this.category != null) {
          docRef = fb.db.collection(path).doc()
        } else {
          docRef = fb.db.collection('asuulga/' + this.asuulgaId + '/questions/').doc()
        }
        docRef.set(newQuestion)
      },
      _deleteQuestion() {
        var path = 'asuulga/' + this.asuulgaId + '/categories/' + this.category.id + '/questions/' + this.question.id
        console.log(path)
        fb.db.doc(path).delete()
      },
      _selectQuestion() {
        console.log('xxx')
      },

    },
  }
</script>
<style>
 .numberOfquestion{
   background: red;
   color: white;
   padding: 10px 10px
 }
</style>
